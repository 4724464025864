import { IStatementTransactions } from '@domain/interfaces/common/statementTransactions/IStatementTransactions';
import { IBalanceProps } from '@domain/interfaces/hooks/services/statementTransaction/IStatementTransactionService';

export enum EBalanceType {
  Available = 'AVAILABLE',
  Pending = 'PENDING',
  Reserved = 'RESERVED',
  All = 'ALL',
}

export interface IGetStatementTransactionsProvider {
  page: number;
  isError: boolean;
  isEmpty: boolean;
  pageLimit: number;
  isLoading: boolean;
  totalPages: number;
  balances: IBalanceProps;
  statementTransactionTotal: number;
  handlePageChange: (selected: any) => void;
  handlePageLimit: (newPageLimit: number) => void;
  statementTransactions: Array<IStatementTransactions>;
  selectedBalance: EBalanceType;
  handleSelectedBalance: (newSelectedBalance: string) => void;
}
