import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IOrder } from '@domain/interfaces/common/order/IOrder';
import { IGetOrderProvider } from '@domain/interfaces/store/common/order/IGetOrderProvider';
import { IRefundPaymentData } from '@domain/interfaces/hooks/services/payment/IPaymentService';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useToast } from '@store/context/common/ToastContext';
import { usePaymentService } from '@hooks/services/payment/usePayment';

const GetOrderContext = React.createContext<IGetOrderProvider | null>(null);

interface IResponse {
  object: IOrder;
}

export const GetOrderProvider: React.FC = ({ children }) => {
  const { accountId, checkoutId, orderId } = useParams();
  const { handleError } = useErrorHandler();
  const { toast } = useToast();
  const { refundPayment } = usePaymentService();

  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
  const [isRefundModalOpen, setIsRefundModalOpen] = React.useState<boolean>(false);
  const [isRefundingOrder, setIsRefundingOrder] = React.useState<boolean>(false);
  const [order, setOrder] = React.useState<IOrder | undefined>(undefined);
  const [showRefundButton, setShowRefundButton] = React.useState<boolean>(false);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const ORDER_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/orders/${orderId}`;

  const { data, isLoading, isValidating, mutate, error } = useSWR<ISwr<IResponse>>(
    ORDER_URL,
    dashboardInstance,
  );

  const handleRefundModalOpen = React.useCallback(() => {
    setIsRefundModalOpen(state => !state);
  }, []);

  const refundOrder = React.useCallback(async () => {
    setIsRefundingOrder(true);

    if (!order?.payment.id) {
      toast.error('Payment ID não encontrado.');

      return;
    }

    try {
      const payload: IRefundPaymentData = {
        payment_id: order?.payment.id,
      };

      await refundPayment({ accountId, payload });

      await new Promise(resolve => setTimeout(resolve, 3000));

      setIsRefundingOrder(false);

      toast.success('Pedido estornado com sucesso!');

      mutate();

      setIsRefundModalOpen(false);
    } catch (requestError) {
      setIsRefundingOrder(false);
    }
  }, [accountId, order, refundPayment, toast, mutate]);

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data?.object) {
      setOrder(data.data.object);
    }
  }, [data, handleError, error]);

  React.useEffect(() => {
    if (order && order.payment.status === 'PAID' && order.payment.processor.id === 'IUGU') {
      setShowRefundButton(true);
    } else {
      setShowRefundButton(false);
    }
  }, [order]);

  const isLoadingOrder = isLoading || isValidating;
  const isOrderError = Boolean(error);

  return (
    <GetOrderContext.Provider
      value={{
        isLoadingOrder,
        isOrderError,
        isOpenModal,
        setIsOpenModal,
        mutate,
        order,
        handleRefundModalOpen,
        isRefundModalOpen,
        isRefundingOrder,
        showRefundButton,
        refundOrder,
      }}
    >
      {children}
    </GetOrderContext.Provider>
  );
};

export const useGetOrder = (): IGetOrderProvider => {
  const context = React.useContext(GetOrderContext);

  if (!context) {
    throw new Error('useGetOrder must be used within provider');
  }

  return context;
};
