import React from 'react';

import { useAnalytics } from '@store/context/common/AnalyticsContext';

import CustomerCard from './CustomerCard';
import PaymentCard from './PaymentCard';
import TransactionDetail from './TransactionDetail';
import ShippingCard from './ShippingCard';
import ProductCard from './ProductCard';
import CustomModal from '../CustomModal';

import * as S from './styles';

const Body: React.FC = () => {
  const { analytics } = useAnalytics();

  React.useEffect(() => {
    analytics.track('Order Details Page Viewed');
  }, [analytics]);

  return (
    <S.Wrapper>
      <CustomModal />

      <CustomerCard />
      <S.PaymentAndDetailWrapper>
        <PaymentCard />
        <TransactionDetail />
      </S.PaymentAndDetailWrapper>

      <ShippingCard />
      <ProductCard />
    </S.Wrapper>
  );
};

export default Body;
