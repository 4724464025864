export const languages = [
  { value: 'de-DE', label: 'Alemão' },
  { value: 'bg-BG', label: 'Búlgaro' },
  { value: 'hr-HR', label: 'Croata' },
  { value: 'sl-SL', label: 'Esloveno' },
  { value: 'es-ES', label: 'Espanhol (Europa)' },
  { value: 'es-AR', label: 'Espanhol (América Latina)' },
  { value: 'fr-FR', label: 'Francês' },
  { value: 'el-GR', label: 'Grego' },
  { value: 'hi-IN', label: 'Hindu' },
  { value: 'nl-NL', label: 'Holandês' },
  { value: 'in-ID', label: 'Indonésio' },
  { value: 'ga-IE', label: 'Irlandês' },
  { value: 'it-IT', label: 'Italiano' },
  { value: 'en-US', label: 'Inglês' },
  { value: 'lt-LT', label: 'Lituano' },
  { value: 'nb-NO', label: 'Norueguês' },
  { value: 'pl-PL', label: 'Polonês' },
  { value: 'pt-PT', label: 'Português (Portugal)' },
  { value: 'ro-RO', label: 'Romeno' },
  { value: 'th-TH', label: 'Tailandês' },
  { value: 'tr-TR', label: 'Turco' },
];
