import React from 'react';

import { useAnalytics } from '@store/context/common/AnalyticsContext';

import ListSkeletonLoading from '@components/common/utils/ListSkeletonLoading';
import BottomPagination from '@components/common/utils/BottomPagination';
import { useGetStatementTransactions } from '@store/common/statementTransactions/GetStatementTransactionsContext';

import Body from './Body';
import Header from './Header';
import BalanceCard from './BalanceCard';

import * as S from './styles';

const StatementTransaction: React.FC = () => {
  const { analytics } = useAnalytics();
  const {
    page,
    balances,
    isLoading,
    pageLimit,
    totalPages,
    handlePageLimit,
    handlePageChange,
    statementTransactionTotal,
  } = useGetStatementTransactions();

  React.useEffect(() => {
    analytics.track('Statement Transaction Page Viewed');
  }, [analytics]);

  if (isLoading) {
    return <ListSkeletonLoading />;
  }

  return (
    <S.Wrapper>
      <Header />
      <BalanceCard balances={balances} />
      <Body />

      <BottomPagination
        page={page}
        pageLimit={pageLimit}
        totalPages={totalPages}
        handlePageLimit={handlePageLimit}
        handlePageChange={handlePageChange}
        totalItems={statementTransactionTotal}
      />
    </S.Wrapper>
  );
};
export default StatementTransaction;
