import styled from 'styled-components';

import ModalBase from '@components/common/DataDisplay/Modal';
import TextBase from '@components/common/DataDisplay/Text';

export const Description = styled(TextBase)`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.neutral.darker};
`;

export const Modal = styled(ModalBase)`
  max-width: 500px;
`;
