import React from 'react';
import { Check, X, Clock, MagnifyingGlass } from 'phosphor-react';

import { EPayoutStatus } from '@domain/enums/common/payouts/EPayoutStatus';

import * as S from './styles';

interface IBadgeProps {
  status: EPayoutStatus;
}

const statusMap = {
  [EPayoutStatus.FAILED]: {
    text: 'Falhou',
    status: EPayoutStatus.FAILED,
    icon: <X size={16} />,
  },
  [EPayoutStatus.PROCESSING]: {
    text: 'Processando',
    status: EPayoutStatus.PROCESSING,
    icon: <Clock size={16} />,
  },
  [EPayoutStatus.CANCELED]: {
    text: 'Cancelada',
    status: EPayoutStatus.CANCELED,
    icon: <X size={16} />,
  },
  [EPayoutStatus.COMPLETED]: {
    text: 'Sucesso',
    status: EPayoutStatus.COMPLETED,
    icon: <Check size={16} />,
  },
  [EPayoutStatus.PENDING]: {
    text: 'Em análise',
    status: EPayoutStatus.PENDING,
    icon: <MagnifyingGlass weight="bold" size={16} />,
  },
  [EPayoutStatus.SENT]: {
    text: 'Enviada',
    status: EPayoutStatus.SENT,
    icon: <Check size={16} />,
  },
};

const Badge: React.FC<IBadgeProps> = ({ status }) => {
  const {
    text,
    status: mappedStatus,
    icon,
  } = statusMap[status] || statusMap[EPayoutStatus.PENDING];

  return (
    <S.Badge status={mappedStatus}>
      {icon} {text}
    </S.Badge>
  );
};

export default Badge;
