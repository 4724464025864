import React from 'react';
import { ChatCircleDots, CurrencyCircleDollar, ShoppingCartSimple } from 'phosphor-react';

import { EBusinessModel } from '@domain/enums/common/onboarding/EUser';

import { useUserAccount } from '@store/context/common/UserAccount';
import { useUserCheckout } from '@store/context/common/UserCheckout';

import { RateCard } from '../RateCard';

export const OperationalFee: React.FC = () => {
  const { currentUserAccount } = useUserAccount();
  const { currentUserCheckout } = useUserCheckout();

  const isInfroproduct =
    currentUserAccount?.business_type === EBusinessModel.INFOPRODUCT ||
    currentUserCheckout?.business_type === EBusinessModel.INFOPRODUCT;

  const operationalFees = [
    {
      icon: <CurrencyCircleDollar size="18" weight="bold" />,
      name: 'Saque',
      fee: 'R$ 4,99',
      textFee: 'por saque realizado',
      description: 'Saque instantaneo via pix',
    },
    {
      icon: <ShoppingCartSimple size="18" weight="bold" />,
      name: 'Checkout',
      fee: isInfroproduct ? '0,00%' : '1,50%',
      textFee: 'por transação aprovada',
      description: 'Taxa de intermediação para vendas online.',
    },
    {
      icon: <ChatCircleDots size={18} weight="bold" />,
      name: 'Smart recovery',
      fee: 'R$ 0,00',
      textFee: 'por recuperação',
      description: 'Isento de cobrança por disparo de SMS',
    },
  ];

  return <RateCard rates={operationalFees} title="Tarifa operacional" />;
};
