import React from 'react';
import lightTheme from '@styles/themes/light';
import { ConfigProvider } from '@store/context/common/ConfigContext';
import { Toaster } from 'react-hot-toast';
import { SkeletonTheme } from 'react-loading-skeleton';
import { ThemeProvider } from 'styled-components/macro';
import { SWRConfig } from 'swr';
import { IntercomProvider } from 'react-use-intercom';

import { ToastProvider } from '@store/context/common/ToastContext';
import { AnalyticsProvider } from '@store/context/common/AnalyticsContext';
import { ErrorHandlerProvider } from '@store/context/common/ErrorHandlerContext';
import { SessionProvider } from '@store/context/common/SessionContext';
import { FeatureToggleProvider } from '@store/context/common/featureToggleContext';

import Routes from './routes';
import GlobalStyle from './styles/global';

const App: React.FC = () => {
  const SWR_OPTIONS = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    revalidateOnReconnect: false,
    dedupingInterval: 5000,
  };

  return (
    <div className="App">
      <SessionProvider>
        <IntercomProvider appId="z5fzd1g7" autoBoot>
          <SWRConfig value={SWR_OPTIONS}>
            <ConfigProvider>
              <AnalyticsProvider>
                <ThemeProvider theme={lightTheme}>
                  <SkeletonTheme baseColor="#E8E9ED" highlightColor="#F0F1F4">
                    <ToastProvider>
                      <ErrorHandlerProvider>
                        <FeatureToggleProvider>
                          <Routes />
                          <GlobalStyle />
                          <Toaster containerStyle={{ zIndex: 999999 }} />
                        </FeatureToggleProvider>
                      </ErrorHandlerProvider>
                    </ToastProvider>
                  </SkeletonTheme>
                </ThemeProvider>
              </AnalyticsProvider>
            </ConfigProvider>
          </SWRConfig>
        </IntercomProvider>
      </SessionProvider>
    </div>
  );
};

export default App;
