import React from 'react';
import { WarningOctagon } from '@phosphor-icons/react';
import { useTheme } from 'styled-components';

import { useGetOrder } from '@store/common/order/GetOrder';

import * as S from './styles';

const RefundModal: React.FC = () => {
  const { colors } = useTheme();
  const { isRefundModalOpen, handleRefundModalOpen, isRefundingOrder, refundOrder } = useGetOrder();

  return (
    <S.Modal
      isOpen={isRefundModalOpen}
      toggle={handleRefundModalOpen}
      title="Estornar pedido"
      icon={<WarningOctagon size={24} weight="fill" color={colors.negative.base} />}
      onSuccess={refundOrder}
      colorSuccessButton={colors.negative.base}
      colorSuccessButtonText={colors.neutral.white}
      successText="Estornar pedido"
      cancelText="Cancelar"
      isSuccessButtonLoading={isRefundingOrder}
    >
      <S.Description>
        Você está prestes a estornar esse pedido. Essa ação é irreversível. Deseja continuar?
      </S.Description>
    </S.Modal>
  );
};

export default RefundModal;
