import { EPayoutStatus } from '@domain/enums/common/payouts/EPayoutStatus';
import styled from 'styled-components/macro';

interface IBadgeProps {
  status: EPayoutStatus;
}

export const Badge = styled.span<IBadgeProps>`
  display: inline-flex;
  width: fit-content;
  gap: 7px;
  padding: 4px 8px;
  height: 23px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  font-family: ${({ theme }) => theme.fonts.text};
  text-transform: uppercase;

  ${({ theme, status }) => {
    if (status === EPayoutStatus.FAILED) {
      return `
        color: ${theme.colors.negative.dark};
        background: ${theme.colors.negative.lightest};
      `;
    }
    if (status === EPayoutStatus.PROCESSING) {
      return `
        color: ${theme.colors.warning.darker};
        background: ${theme.colors.warning.lighter};
      `;
    }
    if (status === EPayoutStatus.CANCELED) {
      return `
        color: ${theme.colors.negative.dark};
        background: ${theme.colors.negative.lightest};
      `;
    }
    if (status === EPayoutStatus.COMPLETED) {
      return `
        color: ${theme.colors.positive.darker};
        background: ${theme.colors.positive.lightest};
      `;
    }
    if (status === EPayoutStatus.PENDING) {
      return `
        color: ${theme.colors.neutral.darker};
        background: ${theme.colors.neutral.lighter};
      `;
    }

    return '';
  }}
`;
