import React from 'react';

import { IBalanceProps } from '@domain/interfaces/hooks/services/statementTransaction/IStatementTransactionService';
import { EBalanceType } from '@domain/interfaces/store/common/statementTransactions/IGetStatementTransactionsProvider';

import Card from './Card';

import * as S from './styles';

interface IBalanceCardProps {
  balances: IBalanceProps;
}

const BALANCE_DATA = [
  {
    id: 'available',
    reference: EBalanceType.Available,
    title: 'Saldo disponível',
    color: '#7FD4A3',
    icon: <S.CheckCircleIcon />,
  },
  {
    id: 'pending',
    reference: EBalanceType.Pending,
    title: 'Saldo a receber',
    color: '#FDEFCD',
    icon: <S.ClockCountDownIcon />,
  },
  {
    id: 'reserved',
    reference: EBalanceType.Reserved,
    title: 'Reserva financeira',
    color: '#D5D5D5',
    icon: <S.PiggyBankIcon />,
  },
  {
    id: 'total',
    reference: EBalanceType.All,
    title: 'Saldo total',
    color: '#D5D5D5',
    icon: <S.CurrencyDollarSimpleIcon />,
  },
];

const BalanceCard: React.FC<IBalanceCardProps> = ({ balances }) => {
  return (
    <S.Wrapper>
      {BALANCE_DATA?.map(balance => (
        <Card key={balance.id} balances={balances} {...balance} />
      ))}
    </S.Wrapper>
  );
};

export default BalanceCard;
