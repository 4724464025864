import React from 'react';
import { Navigate, Routes as ReactRouterDOMRoutes, Route } from 'react-router-dom';

import ListOrderBumps from '@pages/Dashboard/OrderBump/ListOrderBumps';
import NewOrderBump from '@pages/Dashboard/OrderBump/NewOrderBump';
import EditOrderbump from '@pages/Dashboard/OrderBump/UpdateOrderbump';
import Upsell from '@pages/Dashboard/Upsell';
import NewUpsell from '@pages/Dashboard/Upsell/NewUpsell';
import EditUpsell from '@pages/Dashboard/Upsell/UpdateUpsell';
import PublicOutlet from '@routes/PublicOutlet';
import SignIn from '@pages/Public/SignIn';
import SignUp from '@pages/Public/SignUp';
import SignUpUserInvited from '@pages/Public/SignUpUserInvited';
import AcceptInviteUserWithAccount from '@pages/Public/AcceptInviteUserWithAccount';
import RecoveryPassword from '@pages/Public/RecoveryPassword';
import ResetPassword from '@pages/Public/ResetPassword';
import Loading from '@pages/Loading';
import UserOnboarding from '@pages/Onboarding/User';
import StoreOnboarding from '@pages/Onboarding/Store';
import Products from '@pages/Dashboard/Products';
import Apps from '@pages/Dashboard/Apps';
import Shipping from '@pages/Dashboard/Shipping';
import NewShipping from '@pages/Dashboard/Shipping/NewShipping';
import ListWebhooks from '@pages/Dashboard/Webhook/ListWebhooks';
import NewWebhook from '@pages/Dashboard/Webhook/NewWebhook';
import EditWebhook from '@pages/Dashboard/Webhook/EditWebhook';
import PaymentCredential from '@pages/Dashboard/PaymentCredential';
import UpdatePixel from '@pages/Dashboard/Pixel/UpdatePixel';
import Pixel from '@pages/Dashboard/Pixel';
import NewPixel from '@pages/Dashboard/Pixel/NewPixel';
import ListOrders from '@pages/Dashboard/Order/ListOrders';
import Coupon from '@pages/Dashboard/Coupon';
import NewCoupon from '@pages/Dashboard/Coupon/NewCoupon';
import UpdateCoupon from '@pages/Dashboard/Coupon/UpdateCoupon';
import NewCheckoutCustomization from '@pages/Dashboard/CheckoutCustomization/NewCheckoutCustomization';
import GetCheckoutCustomization from '@pages/Dashboard/CheckoutCustomization/GetCheckoutCustomization';
import General from '@pages/Dashboard/General';
import UpdateCheckoutCustomization from '@pages/Dashboard/CheckoutCustomization/UpdateCheckoutCustomization';
import GetDomains from '@pages/Dashboard/Domains/GetDomains';
import ConfigDomains from '@pages/Dashboard/Domains/ConfigDomains';
import GetSocialProof from '@pages/Dashboard/SocialProof/GetSocialProof';
import NewSocialProof from '@pages/Dashboard/SocialProof/NewSocialProof';
import UpdateSocialProof from '@pages/Dashboard/SocialProof/UpdateSocialProof';
import Home from '@pages/Dashboard/Home';
import Recovery from '@pages/Dashboard/recovery';
import AccountData from '@pages/Dashboard/AccountData';
import Profile from '@pages/Dashboard/Profile';
import ListAbandonedCart from '@pages/Dashboard/AbandonedCart/ListAbandonedCart';
import DetailAbandonedCart from '@pages/Dashboard/AbandonedCart/DetailAbandonedCart';
import UpdateShipping from '@pages/Dashboard/Shipping/UpdateShipping';
import TransactionalEmail from '@pages/Dashboard/TransactionaEmail';
import GetOrder from '@pages/Dashboard/Order/GetOrder';
import Shopify from '@pages/Dashboard/Shopify';

import Reportana from '@pages/Dashboard/Reportana';
import GTM from '@pages/Dashboard/GTM';
import GoogleAnalytics from '@pages/Dashboard/GA4';
import MyRates from '@pages/Dashboard/MyRates';
import Users from '@pages/Dashboard/Users';
import Groups from '@pages/Dashboard/Groups/NewGroup';
import EditGroups from '@pages/Dashboard/Groups/EditGroup';
import Payout from '@pages/Dashboard/Payout';
import NewBankAccount from '@pages/Dashboard/BankAccount/NewBankAccount';
import GetBankAccount from '@pages/Dashboard/BankAccount/GetBankAccount';
import StatementTransactions from '@pages/Dashboard/StatementTransaction';
import DashboardOutlet from './DashboardOutlet';
import OnboardingOutlet from './OnboardingOutlet';

const Routes: React.FC = () => {
  return (
    <ReactRouterDOMRoutes>
      <Route path="/" element={<PublicOutlet />}>
        <Route index element={<SignIn />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route path="invite-signup" element={<SignUpUserInvited />} />
        <Route path="accept-invite" element={<AcceptInviteUserWithAccount />} />
        <Route path="recovery-password" element={<RecoveryPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
      </Route>

      <Route path="/loading" element={<Loading />} />

      <Route path="/onboarding" element={<OnboardingOutlet />}>
        <Route path="user" element={<UserOnboarding />} />
        <Route path="store" element={<StoreOnboarding />} />
      </Route>

      <Route path="/:accountId/:checkoutId/dashboard" element={<DashboardOutlet />}>
        <Route path="products/all" element={<Products />} />
        <Route path="apps" element={<Apps />} />
        <Route path="payout" element={<Payout />} />
        <Route path="bank-accounts" element={<GetBankAccount />} />
        <Route path="bank-accounts/new" element={<NewBankAccount />} />
        <Route path="statement-transactions" element={<StatementTransactions />} />
        <Route path="apps/shopify" element={<Shopify />} />
        <Route path="apps/reportana" element={<Reportana />} />
        <Route path="apps/gtm" element={<GTM />} />
        <Route path="apps/ga4" element={<GoogleAnalytics />} />
        <Route path="home" element={<Home />} />
        <Route path="reports/general" element={<General />} />
        <Route path="apps/webhooks" element={<ListWebhooks />} />
        <Route path="apps/webhooks/:webhookId" element={<EditWebhook />} />
        <Route path="apps/webhooks/new" element={<NewWebhook />} />
        <Route path="checkout/customizations" element={<GetCheckoutCustomization />} />
        <Route path="checkout/customizations/new" element={<NewCheckoutCustomization />} />
        <Route
          path="checkout/customizations/:checkoutCustomizationId"
          element={<UpdateCheckoutCustomization />}
        />
        <Route path="checkout/shipping" element={<Shipping />} />
        <Route path="checkout/shipping/:shippingId" element={<UpdateShipping />} />
        <Route path="checkout/domains" element={<GetDomains />} />
        <Route path="checkout/domain/:domainId" element={<ConfigDomains />} />
        <Route path="checkout/shipping/new" element={<NewShipping />} />
        <Route path="marketing/upsells" element={<Upsell />} />
        <Route path="marketing/upsells/new" element={<NewUpsell />} />
        <Route path="marketing/upsells/:upsellId" element={<EditUpsell />} />
        <Route path="marketing/order-bumps" element={<ListOrderBumps />} />
        <Route path="marketing/order-bumps/new" element={<NewOrderBump />} />
        <Route path="marketing/order-bumps/:orderBumpId" element={<EditOrderbump />} />
        <Route path="checkout/social-proofs" element={<GetSocialProof />} />
        <Route path="checkout/social-proof/new" element={<NewSocialProof />} />
        <Route path="checkout/social-proof/:socialProofId" element={<UpdateSocialProof />} />
        <Route path="marketing/pixels/:pixelId" element={<UpdatePixel />} />
        <Route path="marketing/pixels" element={<Pixel />} />
        <Route path="marketing/pixel/new" element={<NewPixel />} />
        <Route path="marketing/coupon" element={<Coupon />} />
        <Route path="marketing/coupon/:couponId" element={<UpdateCoupon />} />
        <Route path="marketing/coupon/new" element={<NewCoupon />} />
        <Route path="marketing/recovery" element={<Recovery />} />
        <Route path="orders/all" element={<ListOrders />} />
        <Route path="orders/:orderId" element={<GetOrder />} />
        <Route path="orders/abandoned-cart" element={<ListAbandonedCart />} />
        <Route path="orders/abandoned-cart/:abandonedCartId" element={<DetailAbandonedCart />} />
        <Route path="payment-credential" element={<PaymentCredential />} />
        <Route path="profile" element={<Profile />} />
        <Route path="settings/gateway-setting" element={<Products />} />
        <Route path="settings/account-data" element={<AccountData />} />
        <Route path="settings/transactional-email" element={<TransactionalEmail />} />
        <Route path="settings/my-rates" element={<MyRates />} />
        <Route path="settings/users" element={<Users />} />
        <Route path="settings/membership-groups" element={<Groups />} />
        <Route path="settings/membership-groups/:membershipGroupsId" element={<EditGroups />} />
      </Route>

      <Route path="*" element={<Navigate to="/" />} />
    </ReactRouterDOMRoutes>
  );
};

export default Routes;
