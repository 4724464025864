import React from 'react';
import { useTheme } from 'styled-components/macro';
import { CaretRight, CaretDown } from 'phosphor-react';
import { useNavigate, useParams } from 'react-router-dom';

import { MENU_OPTIONS } from '@constants/components/sideBar/menuOptions';
import { SUB_MENU_OPTIONS } from '@constants/components/sideBar/subMenuOptions';

import { useUserAccount } from '@store/context/common/UserAccount';
import { checkUserPermissions } from '@utils/common/checkUserPermissions';
import { useFeatureToggle } from '@store/context/common/featureToggleContext';
import { useUserSession } from '@store/context/common/UserSession';
import * as S from './styles';

interface IMenuOptionProps {
  option: (typeof MENU_OPTIONS)[number];
  selectedOption?: string;
  selectedSubItemOption?: string;
  selectedItemOption?: string;
  handleSelectedItemOption: (option: string | undefined) => void;
  handleSelectedOption: (option: string | undefined) => void;
  handleSelectedSubItemOption: (option: string | undefined) => void;
}

const MenuOption: React.FC<IMenuOptionProps> = ({
  option,
  selectedOption,
  handleSelectedOption,
  handleSelectedSubItemOption,
  selectedSubItemOption,
  handleSelectedItemOption,
  selectedItemOption,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const { currentUserAccount } = useUserAccount();
  const { user } = useUserSession();

  const { isEnable } = useFeatureToggle();

  const Icon = option.icon;
  const { hasSubItem } = option;
  const isUserGroupEnabled = isEnable('is_show_users_page');

  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
  const [subItems, setSubItems] = React.useState<Array<(typeof SUB_MENU_OPTIONS)[number]>>([]);

  const onOptionClick = React.useCallback(() => {
    if (isMenuOpen) {
      handleSelectedOption(undefined);
    } else {
      handleSelectedOption(option.name);
    }

    if (!hasSubItem) {
      handleSelectedItemOption(option.name);
      handleSelectedSubItemOption(undefined);
      navigate(`/${accountId}/${checkoutId}/dashboard${option.path}`);
    }
  }, [
    accountId,
    checkoutId,
    handleSelectedOption,
    hasSubItem,
    isMenuOpen,
    navigate,
    option,
    handleSelectedSubItemOption,
    handleSelectedItemOption,
  ]);

  const onSubItemClick = React.useCallback(
    subItem => {
      const isPathContainURL = subItem.path.includes('http');
      if (isPathContainURL) return window.open(subItem.path, '_blank');

      handleSelectedItemOption(undefined);
      handleSelectedSubItemOption(subItem.name);
      navigate(`/${accountId}/${checkoutId}/dashboard${subItem.path}`);
      return undefined;
    },
    [accountId, checkoutId, navigate, handleSelectedItemOption, handleSelectedSubItemOption],
  );

  React.useEffect(() => {
    if (selectedOption === option.name) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }, [selectedOption, option]);

  React.useEffect(() => {
    const filteredSubMenuOptions = SUB_MENU_OPTIONS.filter(subItem =>
      checkUserPermissions({
        type: user?.user?.type,
        scopes: currentUserAccount?.scopes || [],
        requiredPermissions: subItem.requiredPermissions,
      }),
    );

    const filteredOptions = filteredSubMenuOptions.filter(
      subItem => subItem.parent === option.name && (subItem.name !== 'users' || isUserGroupEnabled),
    );

    setSubItems(filteredOptions);
  }, [option, currentUserAccount?.scopes, isUserGroupEnabled, user]);

  React.useEffect(() => {
    const { pathname } = window.location;

    const foundSubMenuOption = SUB_MENU_OPTIONS.find(subItem => pathname.includes(subItem.path));

    if (foundSubMenuOption?.parent === option.name) {
      setIsMenuOpen(true);
    }
  }, [option]);

  return (
    <>
      <S.Wrapper onClick={onOptionClick} isSelected={selectedItemOption === option.name}>
        <S.IconAndRouteNameWrapper>
          <Icon size={18} color={theme.colors.neutral.darker} weight="bold" />
          <S.RouteName>{option.parsedName}</S.RouteName>
        </S.IconAndRouteNameWrapper>

        {hasSubItem && isMenuOpen && (
          <CaretDown size={12} color={theme.colors.neutral.darker} weight="bold" />
        )}

        {hasSubItem && !isMenuOpen && (
          <CaretRight size={12} color={theme.colors.neutral.darker} weight="bold" />
        )}
      </S.Wrapper>

      {isMenuOpen && option.hasSubItem && (
        <S.SubItemsWrapper>
          {subItems.map(item => (
            <S.SubItem
              key={item.name}
              onClick={() => onSubItemClick(item)}
              isSelected={item.name === selectedSubItemOption}
            >
              <S.BulletWrapper>
                <S.Bullet isSelected={item.name === selectedSubItemOption} />
              </S.BulletWrapper>

              <S.SubItemRouteName>{item.parsedName}</S.SubItemRouteName>
            </S.SubItem>
          ))}

          <S.BarTray />
        </S.SubItemsWrapper>
      )}
    </>
  );
};

export default MenuOption;
