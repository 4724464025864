import React from 'react';

import {
  CheckCircle,
  XCircle,
  Clock,
  Truck,
  Eye,
  WarningCircle,
  CaretCircleDoubleLeft,
} from 'phosphor-react';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';

import boletoIcon from '@assets/images/common/paymentMethod/boleto.svg';
import pixIcon from '@assets/images/common/paymentMethod/pix.svg';

import { EFulfillmentStatus } from '@domain/enums/common/fulfillment/EFulfillmentStatus';
import { IOrder } from '@domain/interfaces/common/order/IOrder';
import { IAccount } from '@domain/interfaces/common/account/IAccount';

import { formatCurrency } from '@utils/common/formatCurrency';
import { getCreditCardIssuer } from '@utils/common/paymentMethod';

import { findTransactionError } from '@utils/common/transactionError';

import * as S from './styles';

interface ITableRowProps {
  order: IOrder;
  account: IAccount;
}

const TableRow: React.FC<ITableRowProps> = ({ order, account }) => {
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();

  const createdAt = format(new Date(order.order.created_at), 'dd/MM/yyyy HH:mm');
  const clientName = order.customer.name;
  const clientEmail = order.customer.email;
  const paymentMethod = order.payment.method;
  const orderStatus = order.order.status;
  const productName = order.products[0]?.name;
  const currency = order?.order?.currency;
  const declinedReason = order?.payment?.decline_reason;

  const declinedReasonText = `${declinedReason} - ${findTransactionError(declinedReason)}`;

  const cardIssuer = order?.card?.brand || '';

  const orderAmountValue = order.order.amount_total / 100;
  const orderAmountInBrlValue = order.order.amount_in_brl / 100;

  // const orderAmount = numberToString(order.order.amount_total / 100, 2);

  const orderAmountFormatted = formatCurrency(orderAmountValue, account.language, currency);
  const isGlobalOrder = order.currency !== 'BRL';

  const productPrice = order.products[0]?.sub_total ? order.products[0]?.sub_total / 100 : 0;

  const productPriceFormatted = formatCurrency(productPrice, account.language, currency);

  const installments = order.payment.installments || 1;

  const orderAmountByInstallment = formatCurrency(
    order.order.amount_in_brl / 100 / installments,
    'pt-BR',
    'BRL',
  );

  const [isHovering, setIsHovering] = React.useState<boolean>(false);

  const redirectToDetails = React.useCallback(() => {
    navigate(`/${accountId}/${checkoutId}/dashboard/orders/${order.order.id}`);
  }, [accountId, checkoutId, navigate, order]);

  const onMouseEnter = (): void => setIsHovering(true);

  const onMouseLeave = (): void => setIsHovering(false);

  const getChipByShippingStatus = React.useCallback(() => {
    const shippingStatus = order?.fulfillments[0]?.status;

    if (
      !order?.fulfillments.length &&
      orderStatus !== 'PAID' &&
      orderStatus !== 'PARTIALLY_REFUNDED' &&
      orderStatus !== 'REFUNDED' &&
      orderStatus !== 'DISPUTED'
    ) {
      return '-';
    }

    if (!order?.fulfillments.length)
      return (
        <S.Chip backgroundColor="#F1F0F5" textColor="#023E8A">
          <Clock color="#023E8A" size={16} />
          AGUARDANDO ENVIO
        </S.Chip>
      );

    if (
      shippingStatus === EFulfillmentStatus.TRACEABLE ||
      shippingStatus === EFulfillmentStatus.IN_TRANSIT ||
      shippingStatus === EFulfillmentStatus.WAITING
    ) {
      return (
        <S.Chip backgroundColor="#F1F0F5" textColor="#023E8A">
          <Truck color="#023E8A" size={16} />
          EM TRÂNSITO
        </S.Chip>
      );
    }

    if (shippingStatus === EFulfillmentStatus.DELIVERED) {
      return (
        <S.Chip backgroundColor="#F1F0F5" textColor="#023E8A">
          <Truck color="#023E8A" size={16} />
          ENTREGUE
        </S.Chip>
      );
    }

    if (shippingStatus === EFulfillmentStatus.FAILED) {
      return (
        <S.Chip backgroundColor="#F1F0F5" textColor="#023E8A">
          <WarningCircle color="#023E8A" size={16} />
          FALHA NA ENTREGA
        </S.Chip>
      );
    }

    return <></>;
  }, [order, orderStatus]);

  const getChipByStatus = React.useCallback(() => {
    if (orderStatus === 'PAID') {
      return (
        <S.Chip backgroundColor="#D4F1E0" textColor="#104928">
          <CheckCircle color="#104928" size={16} />
          PAGO
        </S.Chip>
      );
    }

    if (orderStatus === 'CANCELED' || orderStatus === 'FAILED' || orderStatus === 'DECLINED') {
      return (
        <S.Chip backgroundColor="#FBCDD2" textColor="#BD2C2D">
          <XCircle color="#BD2C2D" size={16} />
          CANCELADO
        </S.Chip>
      );
    }

    if (orderStatus === 'UNPAID' && paymentMethod === 'CREDIT_CARD') {
      return (
        <S.Tooltip>
          <S.TooltipTrigger>
            <S.Chip backgroundColor="#F8D8D8" textColor="#581717">
              <XCircle color="#581717" size={16} />
              RECUSADO
            </S.Chip>
            <S.TooltipContent sideOffset={6}>
              <S.RefusedText>Motivo da recusa:</S.RefusedText>
              <S.DescriptionText>{declinedReasonText}</S.DescriptionText>
              <S.Tooltip.Arrow />
            </S.TooltipContent>
          </S.TooltipTrigger>
        </S.Tooltip>
      );
    }

    if (orderStatus === 'UNPAID') {
      return (
        <S.Chip backgroundColor="#E3E3E3" textColor="#282828">
          <XCircle color="#282828" size={16} />
          NÃO PAGO
        </S.Chip>
      );
    }

    if (orderStatus === 'REFUNDED' || orderStatus === 'PARTIALLY_REFUNDED') {
      return (
        <S.Chip backgroundColor="#E3E3E3" textColor="#282828">
          <CaretCircleDoubleLeft color="#282828" size={16} />
          ESTORNADO
        </S.Chip>
      );
    }

    if (orderStatus === 'DISPUTED') {
      return (
        <S.Chip backgroundColor="#E3E3E3" textColor="#581717">
          <CaretCircleDoubleLeft color="#581717" size={16} />
          CHARGEBACK
        </S.Chip>
      );
    }

    if (orderStatus === 'PENDING' || orderStatus === 'AWAITING_PAYMENT') {
      return (
        <S.Chip backgroundColor="#F8D8D8" textColor="#986500">
          <Clock color="#986500" size={16} />
          PENDENTE
        </S.Chip>
      );
    }

    return <></>;
  }, [orderStatus, paymentMethod, declinedReasonText]);

  const getPaymentValue = React.useCallback(() => {
    if (paymentMethod === 'PIX') {
      return (
        <S.PaymentMethod>
          <S.CardIssuerWrapper src={pixIcon} />
          <S.PaymentMethodData>
            <S.TransactionAmount>{orderAmountFormatted}</S.TransactionAmount>
          </S.PaymentMethodData>
        </S.PaymentMethod>
      );
    }

    if (paymentMethod === 'BOLETO') {
      return (
        <S.PaymentMethod>
          <S.CardIssuerWrapper src={boletoIcon} />
          <S.PaymentMethodData>
            <S.TransactionAmount>{orderAmountFormatted}</S.TransactionAmount>
          </S.PaymentMethodData>
        </S.PaymentMethod>
      );
    }

    if (paymentMethod === 'CREDIT_CARD') {
      return (
        <S.PaymentMethod>
          <S.CardIssuerWrapper src={getCreditCardIssuer[cardIssuer]} />
          <S.PaymentMethodData>
            <S.TransactionAmount>{orderAmountFormatted}</S.TransactionAmount>
            {isGlobalOrder ? (
              <S.InstallmentAmount>{`${orderAmountByInstallment}`}</S.InstallmentAmount>
            ) : (
              <S.InstallmentAmount>{`${installments}x ${orderAmountByInstallment}`}</S.InstallmentAmount>
            )}
          </S.PaymentMethodData>
        </S.PaymentMethod>
      );
    }

    return <></>;
  }, [
    paymentMethod,
    installments,
    orderAmountByInstallment,
    orderAmountFormatted,
    cardIssuer,
    isGlobalOrder,
  ]);

  return (
    <S.Table.Row borderBottom onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <S.Table.Data>
        <S.OrderIdAndDateWrapper>
          <S.OrderIdText to={order.shopify?.order_url} target="_blank">
            {order.order?.provider_id}
          </S.OrderIdText>

          <S.DateText>{createdAt}</S.DateText>
        </S.OrderIdAndDateWrapper>
      </S.Table.Data>

      <S.Table.Data>
        <S.Customer>
          <S.CustomerName>{clientName}</S.CustomerName>
          <S.CustomerEmail>{clientEmail}</S.CustomerEmail>
        </S.Customer>
      </S.Table.Data>

      <S.Table.Data>{getPaymentValue()}</S.Table.Data>

      <S.Table.Data>{getChipByStatus()}</S.Table.Data>

      <S.Table.Data>
        <S.Product>
          <S.ProductName>{productName}</S.ProductName>

          <S.ProductPrice>{productPriceFormatted}</S.ProductPrice>
        </S.Product>
      </S.Table.Data>

      <S.Table.Data>{getChipByShippingStatus()}</S.Table.Data>

      <S.Table.Data>
        <S.Actions>
          {isHovering && <Eye onClick={redirectToDetails} size={20} weight="bold" />}
        </S.Actions>
      </S.Table.Data>
    </S.Table.Row>
  );
};

export default TableRow;
